<template lang="pug">
  .links
    a.link(v-for="link in links" :href="link.link" :class="{ 'link--dark': dark}" target="_blank")
      font-awesome-icon.icon(:icon="['fab', link.icon]")
</template>

<script>
import SocialLinks from "@/contentful/SocialLinks";

export default {
  name: "SocialLinks",
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      links: []
    };
  },
  async created() {
    this.links = await SocialLinks.get();
  }
};
</script>

<style scoped lang="sass">
$social-links-base-size: 1rem !default;
$social-links-primary-color: black !default;
$social-links-secondary-color: white !default;
$social-links-dark-primary-color: grey !default;
$social-links-dark-secondary-color: white !default;

.link
  align-items: center
  display: inline-flex
  border: 1px solid $social-links-primary-color
  justify-content: center
  width:  $social-links-base-size
  padding: $social-links-base-size / 2
  transition: background-color 0.2s ease

  &:not(:last-child)
    margin-right: 1rem

  &:hover
    background-color: $social-links-primary-color

    .icon
      color: $social-links-secondary-color

  &.link--dark
    border-color: $social-links-dark-primary-color

    .icon
      color: $social-links-dark-primary-color

    &:hover
      border-color: $social-links-dark-secondary-color
      .icon
        color: $social-links-dark-secondary-color

  .icon
    color: $social-links-primary-color
    transition: color 0.5s ease
    font-size: $social-links-base-size
</style>

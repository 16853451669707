<template lang="pug">
  .competencies
    .competencies__item(v-for="competency in competencies")
      font-awesome-icon.competencies__icon(:icon="competency.icon")
      .competencies__title {{ competency.title }}
      .competencies__body {{ competency.body }}
</template>

<script>
import Competencies from "@/contentful/Competencies";

export default {
  name: "TheCompetencies",
  data: () => {
    return {
      competencies: []
    };
  },
  async created() {
    this.competencies = await Competencies.get();
  }
};
</script>

<style scoped lang="sass">
$icon-size: 3rem

$block: "competencies"

+component()
  display: flex
  flex-wrap: wrap
  text-align: center
  justify-content: space-evenly

  +has(item)
    width: 300px
    margin-bottom: 2rem

  +has(icon)
    height: $icon-size
    width: $icon-size
    font-size: $icon-size
    margin-bottom: 1rem

  +has(title)
    font-size: 1.4rem
    margin-bottom: 0.8rem
    text-transform: uppercase

  +has(body)
    line-height: 1.8rem
    color: DarkSlateGray
</style>

import { render, staticRenderFns } from "./Variables.vue?vue&type=template&id=e8a944e0&scoped=true&lang=pug&"
import script from "./Variables.vue?vue&type=script&lang=js&"
export * from "./Variables.vue?vue&type=script&lang=js&"
import style0 from "./Variables.vue?vue&type=style&index=0&id=e8a944e0&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8a944e0",
  null
  
)

export default component.exports
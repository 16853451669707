import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

/**
 * Import globally used styling
 */
import "@/assets/sass/main.sass";
import "flexboxgrid/dist/flexboxgrid.min.css";

/**
 * vue-code-hightlight themes
 *
 * Options: duotone-sea, prism-coy, prism-dark, prism-funky, prism-okaidia,
 *          prism-solarizedlight, prism-tomorrow, prism-twilight
 **/
import "../node_modules/vue-code-highlight/themes/duotone-sea.css";

/**
 * Import and use font awesome icons that are needed
 */
import {
  faFileDownload,
  faCog,
  faDesktop,
  faCloudUploadAlt,
  faLayerGroup,
  faChartLine,
  faHeadset
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faFacebook,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
library.add([
  faGithub,
  faLinkedin,
  faFacebook,
  faTwitter,
  faFileDownload,
  faCog,
  faDesktop,
  faCloudUploadAlt,
  faLayerGroup,
  faChartLine,
  faHeadset
]);

/**
 * Import and register globally used components
 */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("default-layout", DefaultLayout);

/**
 * Configuration
 */
Vue.config.productionTip = false;

/**
 * Initiate the application
 */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

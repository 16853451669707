<template lang="pug">
  .home
    section
      .container: .row
        .col-xs-12
          the-profile
    section.midtone
      .container: .row
        .col-xs-12
          the-competencies
    section
      .container: .row
        .col-xs-12
          the-skills
    section.midtone
      .container: .row
        .col-xs-12
          the-values
</template>

<script>
import TheSkills from "@/components/TheSkills.vue";
import TheValues from "@/components/TheValues.vue";
import TheProfile from "@/components/TheProfile.vue";
import TheCompetencies from "@/components/TheCompetencies.vue";

export default {
  name: "home",
  components: {
    TheSkills,
    TheValues,
    TheProfile,
    TheCompetencies
  }
};
</script>

import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "competencies",
      order: "fields.order"
    });

    return entries.items.map(item => {
      return {
        title: item.fields.title,
        icon: item.fields.icon,
        body: item.fields.body
      };
    });
  }
};

<template lang="pug">
  .progress-bar
    .progress-bar__body
      .progress-bar__title {{ title }}
      .progress-bar__value {{ progress }}%
    
    .progress-bar__bar
      .progress-bar__progress(:style="style")
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      default: 5
    },
    title: {
      type: String
    }
  },
  computed: {
    style() {
      return `width: ${this.progress}%`;
    }
  }
};
</script>

<style scoped lang="sass">
$block: "progress-bar"
$progress-bar-background-color: lightgrey !default
$progress-bar-progress-background-color: linear-gradient(to right, black, Gray)

+component
  +has(body)
    display: flex
    justify-content: space-between

  +has(value)
    font-weight: bold

  +has(bar)
    background: $progress-bar-background-color
    height: 5px

  +has(progress)
    background: $progress-bar-progress-background-color
    height: inherit
</style>

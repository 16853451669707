<template lang="pug">
  .the-navigation
    router-link.the-navigation__link(to="/") Home
    router-link.the-navigation__link(to="/projects") Projects
</template>

<script>
export default {
  name: "TheNavigation"
};
</script>

<style scoped lang="sass">
$the-navigation-link-color: #333  !default
$the-navigation-link-hover-color: white !default
$the-navigation-link-hover-background-color: #333 !default
$block: "the-navigation"

+component()
  display: flex
  padding-top: 1rem
  flex-direction: row
  padding-bottom: 1rem
  justify-content: flex-end

  +has(link)
    font-size: 1.6rem
    margin-left: 1rem
    text-decoration: none
    color: $the-navigation-link-color
    transition: all 0.5s ease
    padding: 0.5rem 1rem

    &:hover
      color: $the-navigation-link-hover-color
      background: $the-navigation-link-hover-background-color
</style>

import contentful from "@/contentful";

const profileID = "3NpCiEsNMIuuaGYMEGiYCa";

export default {
  async get() {
    const entry = await contentful.getEntry(profileID);
    console.log(entry);
    entry.fields.resume = {
      url: "https:" + entry.fields.resume.fields.file.url,
      alt: entry.fields.resume.fields.file.fileName
    };
    entry.fields.image = {
      url: "https:" + entry.fields.image.fields.file.url,
      alt: entry.fields.image.fields.description
    };
    return entry.fields;
  }
};

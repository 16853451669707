<template lang="pug">
  .variables
    .variables__variable(v-for="variable in variables")
      .variables__key {{ variable.key }}
      .variables__value {{ variable.value }}
</template>

<script>
import Variables from "@/contentful/Variables";

export default {
  name: "Variables",
  data: () => {
    return {
      variables: []
    };
  },
  async created() {
    this.variables = await Variables.get();
  }
};
</script>

<style scoped lang="sass">
$block: "variables"

+component
  display: flex
  flex-wrap: wrap
  margin-bottom: 2rem

  +has(variable)
    display: flex
    min-width: 100%
    line-height: 1.8rem

    +when-screen-is(sm)
      min-width: 50%

  +has(key)
    width: 80px
    font-weight: bold
    margin-right: 2rem
</style>

<template lang="pug">
  component(:is="layout")
    router-view
</template>

<script>
export default {
  name: "app",
  computed: {
    layout: function() {
      return (this.$route.meta.layout || "default") + "-layout";
    }
  }
};
</script>

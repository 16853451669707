import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "variable"
    });

    return entries.items.map(item => {
      return {
        key: item.fields.key,
        value: item.fields.value
      };
    });
  }
};

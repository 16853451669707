<template lang="pug">
  .values
    .values__item(v-for="(value, index) in values")
      .values__number 0{{ index + 1 }}
      .values__title {{ value.title }}
      .values__body {{ value.body }}
</template>

<script>
import CoreValues from "@/contentful/CoreValues";

export default {
  name: "CoreValues",
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      values: []
    };
  },
  async created() {
    this.values = await CoreValues.get();
  }
};
</script>

<style scoped lang="sass">
$block: "values"

+component()
  display: flex
  flex-wrap: wrap
  justify-content: space-evenly

  +has(item)
    width: 200px
    margin-bottom: 2rem

  +has(number)
    font-size: 4rem

  +has(title)
    font-weight: bold
    font-size: 1.2rem
    margin-bottom: .5rem
    text-transform: uppercase

  +has(body)
    line-height: 1.8rem
</style>

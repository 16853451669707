import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "coreValues"
    });

    return entries.items.map(item => {
      return {
        title: item.fields.title,
        body: item.fields.body
      };
    });
  }
};

<template lang="pug">
  #app
    .container: .row
      .col-xs-12
        the-navigation
    slot
    section.dark
      .container: .row
        .col-xs-12
          the-footer
</template>

<script>
import TheNavigation from "@/components/TheNavigation.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "default-layout",
  components: {
    TheFooter,
    TheNavigation
  }
};
</script>

<style lang="sass">
$primary-color: black !default
$secondary-color: white !default
$midtone-background-color: WhiteSmoke !default

#app
  font-family: "Avenir", Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50

.container > .row
  padding: 0 2rem

h1
  margin-top: 0

h2
  font-size: 2rem
  line-height: 2rem
  margin: 0
  display: inline-block
  border-bottom: 2px solid black
  padding-bottom: 0.5rem

.text-center
  text-align: center

section
  padding: 4rem 0

  &.dark
    color: $secondary-color
    background: $primary-color

  &.midtone
    background: $midtone-background-color
</style>

<template lang="pug">
  .the-footer
    .the-footer__copyright Copyright © {{ year }}. Alle rettigheder forbeholdt, Henrik Oldenborg.
    social-links.the-footer__social-links(dark)
</template>

<script>
import SocialLinks from "@/components/SocialLinks.vue";

export default {
  name: "TheFooter",
  components: {
    SocialLinks
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped lang="sass">

$block: "the-footer"

+component()
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: column
  text-align: center

  +when-screen-is(md)
    flex-direction: row

    +then(copyright)
      margin-bottom: 0

  +has(copyright)
    margin-bottom: 1.4rem
</style>

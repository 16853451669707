import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "skills",
      order: "-fields.level"
    });

    const includes = entries.includes.Entry;
    let categories = {};

    includes.forEach(item => {
      categories[item.sys.id] = {
        name: item.fields.name,
        skills: []
      };
    });

    entries.items.forEach(item => {
      if (!item.fields.categories) {
        return;
      }
      item.fields.categories.forEach(category => {
        categories[category.sys.id].skills.push({
          title: item.fields.title,
          level: item.fields.level * 10
        });
      });
    });
    return categories;
  }
};

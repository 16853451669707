<template lang="pug">
  .skills
    .skills__category(v-for="category in skillsByCategory")
      .skills__title {{ category.name  }}
      .skills__body
        .skills__skill(v-for="skill in category.skills")
          progress-bar(:progress="skill.level" :title="skill.title")
</template>

<script>
import Skills from "@/contentful/Skills";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "TheSkills",
  components: {
    ProgressBar
  },
  data: () => {
    return {
      skillsByCategory: []
    };
  },
  async created() {
    this.skillsByCategory = await Skills.get();
  }
};
</script>

<style scoped lang="sass">
$block: "skills"
+component()
  display: flex
  flex-direction: row
  flex-wrap: wrap

  +has(title)
    font-size: 1.4rem
    margin-bottom: 0.8rem
    text-transform: uppercase

  +has(skill)
    margin-bottom: 2rem

  +has(category)
    width: 100%
    margin-bottom: 1.4rem
    box-sizing: border-box

    +when-screen-is(sm)
      width: 50%
      &:nth-child(odd)
        padding-right: 1rem
      &:nth-child(even)
        padding-left: 1rem
</style>

<template lang="pug">
  .the-profile(v-if="profile")
    .the-profile__picture-frame
      .the-profile__picture
          img(:src="profile.image.url" :alt="profile.image.alt" width="100%")
      a.the-profile__download(download :href="profile.resume.url" target="_blank")
          .the-profile__download-icon
            font-awesome-icon(icon="file-download")
          |Download CV
    .the-profile__body
      .the-profile__title {{ profile.title }}
      .the-profile__subtitle {{ profile.subtitle }}
      .the-profile__bio {{ profile.body}}
      variables
      social-links
</template>

<script>
import SocialLinks from "@/components/SocialLinks.vue";
import Variables from "@/components/Variables.vue";
import Profile from "@/contentful/Profile";

export default {
  name: "TheProfile",
  components: {
    SocialLinks,
    Variables
  },
  data: () => {
    return {
      profile: null
    };
  },
  async created() {
    this.profile = await Profile.get();
  }
};
</script>

<style scoped lang="sass">
$block: "the-profile"
+component()
  display: flex
  flex-direction: column

  +when-screen-is(sm)
    flex-direction: row

  +has(picture-frame)
    +when-screen-is(sm)
      margin-right: 2rem

  +has(picture)
    min-width: 200px

    +when-screen-is(md)
      min-width: 300px

  +has(title)
    font-size: 2rem
    font-weight: bold

  +has(subtitle)
    font-style: italic
    font-size: 1.4rem
    margin-bottom: 1rem

  +has(bio)
    line-height: 1.8rem
    margin-bottom: 2rem

  +has(download)
    padding: 1rem
    color: inherit
    display: block
    text-align: center
    margin-bottom: 1rem
    text-decoration: none
    border: 1px solid black

  +has(download-icon)
      display: inline-block
      margin-right: 1rem
</style>

import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "socialLinks"
    });

    return entries.items.map(item => {
      return {
        icon: item.fields.icon,
        title: item.fields.title,
        link: item.fields.link
      };
    });
  }
};
